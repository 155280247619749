import axios from 'axios';
import { format, parseISO, startOfMonth, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Card, Label, Position, Username,Value,RowRanking, Row } from '../components/atomics';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReactLoading from 'react-loading';
import colors from '../colors';
import { API_URL } from '../constants';

const getPeriod = (period) =>({
  weekly: 'Semanal',
  monthly: 'Mensal',
}[period]||'');

const Ranking = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    const search = document.location.search
    const getRanking = async()=>{
      setLoading(true)
      const response = await axios.get(`${API_URL}/ranking-results${search}`);
      setData(response.data)
      setLoading(false)
    }
    getRanking()
  },[]);

  return (
    <Layout>
      <SEO title="Ranking" />
      {data?.type === 'premium' ? (
        <h3 style={{marginTop:"20px",marginBottom:5}}>RESULTADO DO RANKING (PREMIUM <span role='img' aria-label='premium'>👑</span>)</h3>
      ):(
        <h3 style={{marginTop:"20px",marginBottom:5}}>RESULTADO DO RANKING</h3>
      )}
      {
        loading || !data.ranking ? (
            <div style={{display:'flex',flex:1,justifyContent:'center',margin:100}}>
              <ReactLoading type='spin' color={colors.primary} height={50} width={50} />
            </div>
          ):(
          <>
            <Row>
              <Label>TIPO:</Label>
              <Value>{getPeriod(data.period)}</Value>
            </Row>
            <Row>
              <Label>PERÍODO:</Label>
              {data.period === 'weekly' ? (
                <Value>{format(subDays(parseISO(data.date),6),'dd/MM')} até {format(parseISO(data.date),'dd/MM')}</Value>
                ):(
                  <Value>{format(startOfMonth(parseISO(data.date)),'dd/MM')} até {format(parseISO(data.date),'dd/MM')}</Value>
                )
              }
            </Row>
            <br/>
              {
                data?.ranking?.map((item,position)=> (
                  <Card key={position}>
                    <RowRanking>
                      <div style={{display:'flex'}}>
                        <Position>
                          {position+1}
                        </Position>
                        <Username>
                          {item.user.name}
                        </Username>
                      </div>
                      <Label>
                        Pontos
                        <Value>{item.score}</Value>
                      </Label>
                    </RowRanking>
                  </Card>
                ))
              }
          </>
        )
      }
    </Layout>
  )
}

export default Ranking;